<template>
  <div v-if="!activeVariant.productHasExpired" class="InstantBuyBox">
    <!-- notify when back -->
    <div v-if="activeVariant && !activeVariant.canAddToCart && !activeVariant.productHasExpired && !globalContent.getAllowBackorders">
      <button
        class="btn btn--secondary mobOnly:!h-32 mobOnly:!px-12"
        @click="showNotifyModal"
      >
        {{ $t('productPage.sizeSelector.watchStockBtn') }}
      </button>
    </div>

    <!-- availble to buy -->
    <TouchNumber
      v-else
      id=""
      v-model="qty"
      :index="0"
      :show-buttons-on-desktop="true"
      :no-input-field="true"
      :attr="attr"
      :loading="cartStore.isLoading"
      @change="change"
    />
  </div>
</template>

<script setup lang="ts">
import { ProductVariantModel } from '~/models/productVariant';
import { ProductModel } from '~/models/product';
import TouchNumber from '~/components/form-elements/TouchNumber.vue';
import { useUiStore } from '~/store/ui';
import { useCartStore } from '~/store/cart';
import { useGlobalContentStore } from '~/store/globalContent';

const uiStore = useUiStore();
const cartStore = useCartStore();
const globalContent = useGlobalContentStore();
const qty = ref(0);
const props = defineProps<{
  activeVariant: ProductVariantModel,
  productItem: ProductModel,
}>();

const attr = computed(()=> {
  const out = { min: 0 } as {min: number, max?:number};
  if (!props.activeVariant.isOnDemandProduct && !globalContent.getAllowBackorders) {
    out.max = props.activeVariant.stockValue;
  }
  return out;
});

onMounted(()=> getQtyFromCart());

watch(
  ()=> cartStore.lastUpdate,
  ()=> {
    getQtyFromCart();
  }
);
watch(
  () => props.activeVariant,
  ()=> {
    getQtyFromCart();
  }
);

const getQtyFromCart = () => {
  qty.value = cartStore.getQuantityFromPartNo(props.activeVariant.partNo);
};

const change = () => {
  cartStore.changeDebouncedQueue({
    partNo: props.activeVariant.partNo,
    quantity: qty.value,
    product: props.activeVariant,
  });
};

const showNotifyModal = () => {
  uiStore.notifyModalPartNo = props.activeVariant.partNo;
  uiStore.setShowNotify(true);
};

</script>

<style scoped lang="postcss">
</style>
